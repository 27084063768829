import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import SEO from "../components/SEO";
import {
  TitleSection,
  TitleSubText,
  ColoredSpan,
  StyledLink,
  StyledA,
  TitleH2Comp,
  TemplateH2,
  TemplateH3,
  CardContainer,
  CommonCard,
  CardH4,
  CardP,
  ReviewCardContainer,
  ReviewCard,
  ReviewLowerSection,
  ReviewCardLowerText,
  ImageSection,
  CommonArrowButton,
  ButtonArrow,
  ImageTitleH2,
  FaqContainer,
  CommonColorButton,
} from "../components/common";
import {
  Containter,
  ThumbnailContainer,
  BlogPostContainer,
  TextBlog,
  List,
  BlogLink,
  BlogA,
  BlogButtonAnchor,
  BlogPoster,
  BlogsContainer,
  BlogCard,
} from "../components/blogPost";

const Blog = ({ location }) => {
  const pages = [
    {
      link: "/walgreens-style-receipts-generator",
      title: "Generate Walgreens Style Pharmacy Receipts ",
      description:
        "Learn how to generate Walgreens pharmacy receipts with logo, items, and tax details. Use Receiptmakerly to generate customized Walgreens digital receipts. ",
    },
    {
      link: "/lyft-style-receipts-generator",
      title: "How to Generate Lyft Style Receipts ",
      description:
        "Learn how to generate a Lyft-style receipt with transportation details. Use Receiptmakerly- the best Lyft receipt maker with customizable templates. ",
    },
    {
      link: "/gas-fuel-petrol-receipts",
      title: "How to Generate Gas Fuel and Petrol Receipts ",
      description:
        "Gas receipts include tax, price, receipt number, filling time, etc. Learn how to make a gas fuel petrol receipt with Receiptmakerly using customized templates. ",
    },
    {
      link: "/business-tax-receipt",
      title: "What is a Business Tax Receipt: Details with Examples",
      description:
        "Do you know how to acquire a business tax receipt? Learn what it is, when, and how to manage this for your business in this article with examples.",
    },
    {
      link: "/cash-receipts",
      title:
        "What are Cash Receipts and Why They are Important for Your Business",
      description:
        "Learn the definition of cash receipts, their usage, importance, and benefits. Get to know the relevant examples for both customers and businesses.",
    },
    {
      link: "/invoice-vs-receipt",
      title: "Invoice vs Receipt: Key Differences and a Definitive Guide",
      description:
        "Invoice and receipts have some differences based on their usage. Know the detailed comparison between an invoice and a receipt with relevant examples.",
    },
    {
      link: "/cash-receipts",
      title:
        "What are Cash Receipts and Why They are Important for Your Business",
      description:
        "Learn the definition of cash receipts, their usage, importance, and benefits. Get to know the relevant examples for both customers and businesses.",
    },
    {
      link: "/itemized-receipt",
      title: "Detailed Guide to Itemized Receipt with Templates and Examples",
      description:
        "An itemized receipt lists all the items purchased with their individual prices. Know the details about an itemized receipt from this article.",
    },
    {
      link: "/organize-receipts",
      title: "5 Best Ways to Organize Receipts and Store Them",
      description:
        "Are you facing issues with organizing the receipts? Go through this article to learn the five ways to organize and store receipts and get expert tips on them.",
    },
    {
      link: "/printing-receipts",
      title: "How to Print Receipt: 3 Easy Steps to Follow",
      description:
        "Do you know why printing receipts is important for your business? Learn the 3-step process and get professional tips on printing and distributing receipts.",
    },
    {
      link: "/rent-receipt",
      title: "How to Write a Rent Receipt: Know the Details with Examples",
      description:
        "Learn how to write a rent receipt easily. Get the detailed guidelines with the sample from this article. Make the best use of the rent receipt.",
    },
  ];

  return (
    <>
      <SEO
        title="Blog"
        description="Receiptmakerly blog is the place to learn about generating online receipts. Know how to make receipts like various renowned brands for your business."
        pathname={location.pathname && location.pathname}
sofwareApp
      />
      <BlogPoster>
        <h1>Our Blog</h1>
      </BlogPoster>
      <BlogsContainer>
        {pages &&
          pages.length > 0 &&
          pages.map((page, index) => (
            <StyledLink to={page.link} color="#132742">
              <BlogCard key={index}>
                <h3> {page.title} </h3>
                <p>
                  {page.description >
                  page.description.split(" ").splice(0, 30).join(" ")
                    ? `${page.description
                        .split(" ")
                        .splice(0, 35)
                        .join(" ")}...`
                    : page.description}
                </p>
              </BlogCard>
            </StyledLink>
          ))}
      </BlogsContainer>
    </>
  );
};

export default Blog;
